.search-window {
    display: none;
    position: fixed;
    top: 10%;
    left: 50%;
    z-index: 9999;
    width: 700px;
    height: 80%;
    margin-left: -350px;
    padding: 0;
    overflow: hidden;
    background: #FFF;
    color: #333;
    font-size: 16px;
    font-family: 'Monda', "PingFang SC", "Microsoft YaHei", sans-serif;
    line-height: 2;
    border-radius: 5px
}

@media (max-width: 720px) {

    .search-window {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        border-radius: 0
    }
}

@media (max-width: 1200px) {

    .search-window {
        font-size: 14px
    }
}

.search-window input {
    font-size: inherit;
    font-family: inherit
}

.search-window a:hover {
    color: #222 !important;
    border-bottom-color: #222
}

.search-content {
    max-height: 50px;
    padding: 10px 15px 5px;
    background: #F5F5F5;
    border-bottom: 1px solid #FFF;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px
}

.search-input {
    width: calc(90% - 30px);
    height: 36px;
    line-height: 36px;
    margin-left: 30px
}

.search-input input {
    width: 100%;
    padding: 5px 0;
    background: transparent;
    outline: none;
    border: none
}

.search-scroll {
    position: relative;
    overflow: auto
}

.search-result {
    position: relative;
    padding: 0 30px;
    overflow: auto
}

.search-result .search-stats {
    padding-top: 10px
}

.search-result hr {
    height: 3px;
    margin: 10px 0;
    background-color: #DDD;
    background-image: repeating-linear-gradient(-45deg, #FFF, #FFF 4px, transparent 4px, transparent 8px);
    border: none;
    box-sizing: content-box
}

.search-result em {
    margin: 0;
    padding: 0 2px;
    background: #F7F7F7;
    overflow: auto;
    color: #F00;
    font-size: inherit;
    font-style: normal;
    line-height: 1.6;
    border-radius: 1px
}

.search-hits {
    margin-top: 20px
}

.search-hits-empty {
    word-break: break-all
}

.search-hit-item {
    margin: 15px 0
}

.search-hit-link {
    display: block;
    color: #555 !important;
    border-bottom: 1px dashed #CCC;
    text-decoration: none; 
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.search-pagination ul {
    margin: 40px 0;
    padding: 0;
    font-size: 14px;
    text-align: center
}

.search-pagination li {
    display: inline-block
}

.search-pagination .page-number {
    display: inline-block;
    position: relative;
    top: -1px;
    margin: 0 10px;
    padding: 0 10px;
    line-height: 30px;
    border-bottom: 1px solid #FFF;
    transition-property: border-color;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.search-pagination .page-number:hover {
    border-bottom: 1px solid #222
}

.search-pagination .current .page-number {
    color: #FFF !important;
    background: #CCC;
}

.search-pagination a {
    color: #555 !important;
    font-size: 14px;
}

.search-pagination .disabled {
    cursor: pointer
}

@media (max-width: 720px) {

    .search-pagination .page-number {
        margin: 0 5px;
        margin-bottom: 10px;
        border-bottom: 1px solid #EEE;
    }
}

.search-cover {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3)
}

.search-close-icon {
    position: absolute;
    top: 9px;
    right: 14px;
    padding-left: 15px;
    font-size: 14px;
    border-left: 1px solid #EEE;
    cursor: pointer;
    text-transform: uppercase
}

.search-close-icon .iconfont {
    color: #999;
    font-size: 18px
}

.search-close-icon:hover .iconfont {
    color: #222
}

.search-content-icon {
    position: absolute;
    width: 20px;
}
.search-content-icon .iconfont {
    font-size: 18px;
    font-weight: bold
}


/* Dark Mode CSS */

@media (prefers-color-scheme: dark) {
        
    :root:not([color-scheme]) .search-window {
        background: #333333;
        color: #DDDDDD
    }

    :root:not([color-scheme]) .search-window a:hover {
        color: #DDDDDD !important;
        border-bottom-color: #EEEEEE
    }

    :root:not([color-scheme]) .search-content {
        background: #3D3D3D;
        border-bottom: 1px solid #333333
    }

    :root:not([color-scheme]) .search-input input {
        color: #DDDDDD
    }

    :root:not([color-scheme]) .search-close-icon {
        border-left: 1px solid #444444
    }

    :root:not([color-scheme]) .search-close-icon:hover .iconfont {
        color: #EEEEEE
    }

    :root:not([color-scheme]) .search-result hr {
        background-color: #555555;
        background-image: repeating-linear-gradient(-45deg, #333333, #333333 4px, transparent 4px, transparent 8px)
    }

    :root:not([color-scheme]) .search-result em {
        background: #3B3B3B
    }

    :root:not([color-scheme]) .search-hit-link {
        color: #BBBBBB !important;
        border-bottom: 1px dashed #666666
    }

    :root:not([color-scheme]) .search-pagination .page-number {
        border-bottom: 1px solid #333333
    }

    :root:not([color-scheme]) .search-pagination .page-number:hover {
        border-bottom: 1px solid #EEEEEE
    }

    :root:not([color-scheme]) .search-pagination .current .page-number {
        color: #333333 !important;
        background: #666666
    }

    :root:not([color-scheme]) .search-pagination a {
        color: #BBBBBB !important
    }

    /* Dark Mode Scroll Style */

    :root:not([color-scheme]) .iScrollIndicator {
        background: rgba(255, 255, 255, 0.5) !important;
        border: 1px solid rgba(51, 51, 51, 0.9) !important
    }
}

[color-scheme=dark] .search-window {
    background: #333333;
    color: #DDDDDD
}

[color-scheme=dark] .search-window a:hover {
    color: #DDDDDD !important;
    border-bottom-color: #EEEEEE
}

[color-scheme=dark] .search-content {
    background: #3D3D3D;
    border-bottom: 1px solid #333333
}

[color-scheme=dark] .search-input input {
    color: #DDDDDD
}

[color-scheme=dark] .search-close-icon {
    border-left: 1px solid #444444
}

[color-scheme=dark] .search-close-icon:hover .iconfont {
    color: #EEEEEE
}

[color-scheme=dark] .search-result hr {
    background-color: #555555;
    background-image: repeating-linear-gradient(-45deg, #333333, #333333 4px, transparent 4px, transparent 8px)
}

[color-scheme=dark] .search-result em {
    background: #3B3B3B
}

[color-scheme=dark] .search-hit-link {
    color: #BBBBBB !important;
    border-bottom: 1px dashed #666666
}

[color-scheme=dark] .search-pagination .page-number {
    border-bottom: 1px solid #333333
}

[color-scheme=dark] .search-pagination .page-number:hover {
    border-bottom: 1px solid #EEEEEE
}

[color-scheme=dark] .search-pagination .current .page-number {
    color: #333333 !important;
    background: #666666
}

[color-scheme=dark] .search-pagination a {
    color: #BBBBBB !important
}

/* Dark Mode Scroll Style */

[color-scheme=dark] .iScrollIndicator {
    background: rgba(255, 255, 255, 0.5) !important;
    border: 1px solid rgba(51, 51, 51, 0.9) !important
}